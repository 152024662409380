import {
  ChangeDetectionStrategy,
  Component,
  computed,
  DestroyRef,
  inject,
} from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { IconComponent } from '@cca/ui';
import {
  AuthenticationFacade,
  PermissionService,
} from '@cca-common/authentication';
import { provideTranslocoScope, TranslocoModule } from '@jsverse/transloco';
import { RouterLink } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { TermsDialogComponent } from '@cca-common/shared-terms';
import { LegalDocumentType } from '@cca-infra/legal-management/v1';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'cca-header-menu',
  imports: [MatMenuModule, IconComponent, TranslocoModule, RouterLink],
  templateUrl: './header-menu.component.html',
  styleUrls: ['./header-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [provideTranslocoScope('web')],
})
export class HeaderMenuComponent {
  permissions = inject(PermissionService);
  public authentication = inject(AuthenticationFacade, {
    optional: true,
  });

  avatarURL = computed(() => {
    const user = this.authentication?.user();
    return user?.userDetail?.avatarURL ?? null;
  });

  private dialog = inject(MatDialog);
  private destroyRef = inject(DestroyRef);

  openTerms() {
    const userId = this.authentication?.user()?.userId;
    const dialogRef = this.dialog.open(TermsDialogComponent, {
      data: {
        documentType: LegalDocumentType.CCATermsOfUse,
        userId: userId,
        readonly: true,
      },
    });
    dialogRef
      .backdropClick()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => dialogRef?.close());
    this.destroyRef.onDestroy(() => dialogRef?.close());
  }
}
