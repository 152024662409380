<ng-container *transloco="let t; read: 'notifications'">
  <header>
    <div class="flex items-center justify-between gap-4">
      <h2>{{ t('title') }}</h2>
      <button
        class="cursor-pointer p-3 text-end font-bold underline text-brand-default"
        (click)="notificationsStore.markAllAsRead()"
      >
        {{ t('markAllAsRead') }}
        <!-- Mark all as read -->
      </button>
    </div>
  </header>
  <main class="relative grow">
    @if (notificationsStore.firstLoad()) {
      <div class="text-center">
        <cca-spinner class="h-12 w-12"></cca-spinner>
      </div>
    } @else {
      <mat-tab-group
        dynamicHeight
        animationDuration="0ms"
        mat-stretch-tabs="true"
        mention
      >
        <mat-tab>
          <ng-template mat-tab-label> {{ t('allNotifications') }} </ng-template>
          <ng-template matTabContent>
            @for (
              notificationList of notificationsStore.notifications();
              track trackNotificationList(notificationList)
            ) {
              <mat-accordion class="!py-0">
                @if (notificationList.notifications.length) {
                  <mat-expansion-panel
                    [expanded]="true"
                    (opened)="notificationList.isPanelOpened = true"
                    (closed)="notificationList.isPanelOpened = false"
                    [hideToggle]="true"
                    class="mat-elevation-z"
                  >
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        <cca-icon
                          [icon]="
                            notificationList.isPanelOpened
                              ? 'chevron-up'
                              : 'chevron-down'
                          "
                          class="text-xl text-neutral-caption"
                        ></cca-icon>
                        <span
                          class="ml-3 pt-0.5 font-bold uppercase text-neutral-caption"
                          >{{
                            notificationList.value
                              | transform: notificationTitle
                              | transloco
                          }}</span
                        >
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    @for (
                      notification of notificationList.notifications;
                      track notification.id
                    ) {
                      <div
                        class="my-1 border-b border-neutral-default last:border-b-0"
                      >
                        <cca-notification-tile
                          [notification]="notification"
                        ></cca-notification-tile>
                      </div>
                    }
                  </mat-expansion-panel>
                }
              </mat-accordion>
              <hr />
            } @empty {
              <p>{{ t('emptyNotifications') }}</p>
            }
            <div class="mt-4 flex justify-center">
              @if (
                !notificationsStore.loading() &&
                notificationsStore.showLoadMoreNotification()
              ) {
                <button
                  mat-button
                  color="primary"
                  (click)="
                    notificationsStore.getNextNotifications(
                      NotificationStatus.All
                    )
                  "
                >
                  {{ t('loadMore') }}
                </button>
              }
            </div>
            @if (notificationsStore.loading()) {
              <div class="text-center">
                <cca-spinner class="h-12 w-12"></cca-spinner>
              </div>
            }
          </ng-template>
        </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label>
            {{ t('unreadNotifications') }}
          </ng-template>
          @for (
            notificationList of notificationsStore.unreadNotifications();
            track notificationList
          ) {
            <mat-accordion class="!py-0">
              @if (notificationList.notifications.length) {
                <mat-expansion-panel
                  [expanded]="true"
                  (opened)="notificationList.isPanelOpened = true"
                  (closed)="notificationList.isPanelOpened = false"
                  [hideToggle]="true"
                  class="mat-elevation-z"
                >
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      <cca-icon
                        [icon]="
                          notificationList.isPanelOpened
                            ? 'chevron-up'
                            : 'chevron-down'
                        "
                        class="text-xl text-neutral-caption"
                      ></cca-icon>
                      <span
                        class="ml-3 pt-0.5 font-bold uppercase text-neutral-caption"
                        >{{
                          notificationList.value
                            | transform: notificationTitle
                            | transloco
                        }}</span
                      >
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  @for (
                    notification of notificationList.notifications;
                    track notification.id
                  ) {
                    <div
                      class="my-1 border-b border-neutral-default last:border-b-0"
                    >
                      <cca-notification-tile
                        [notification]="notification"
                      ></cca-notification-tile>
                    </div>
                  }
                </mat-expansion-panel>
              }
            </mat-accordion>
            <hr />
          } @empty {
            <p>{{ t('emptyUnreadNotifications') }}</p>
          }
          <div class="flex justify-center">
            @if (
              !notificationsStore.loading() &&
              notificationsStore.showLoadMoreUnreadNotification()
            ) {
              <button
                mat-button
                color="primary"
                (click)="
                  notificationsStore.getNextNotifications(
                    NotificationStatus.Unread
                  )
                "
              >
                {{ t('loadMore') }}
              </button>
            }
          </div>
          @if (notificationsStore.loading()) {
            <div class="text-center">
              <cca-spinner class="h-12 w-12"></cca-spinner>
            </div>
          }
        </mat-tab>
      </mat-tab-group>
    }
  </main>
</ng-container>
