<div
  annoying
  class="pointer-events-auto my-4 flex w-96 cursor-pointer gap-4 rounded-xl border p-4 surface-neutral-default border-neutral-disabled"
>
  <cca-icon
    class="inline-table h-6 w-6 rounded-full p-2 text-center align-middle text-2xl leading-none info-surface-lighter info-text"
    [icon]="getNotificationIconType()"
  ></cca-icon>
  <div class="flex w-full flex-col gap-1" (click)="onClick()">
    <h3 class="text-base font-bold text-neutral-body">
      {{ notification().title }}
    </h3>
    <span class="text-xs text-neutral-caption">{{
      notification().sentAt | ccaDate: 'f'
    }}</span>
  </div>
  <button
    type="button"
    (click)="onClose($event)"
    class="pointer h-5 w-5 cursor-pointer place-items-center text-xl leading-4 text-neutral-caption"
  >
    <cca-icon icon="circle-xmark"></cca-icon>
  </button>
</div>
