import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  output,
  input,
} from '@angular/core';
import { IconComponent } from '@cca/ui';
import { CdkDatePipe } from '@cca-common/cdk';
import { provideTranslocoScope } from '@jsverse/transloco';
import {
  WebPushMessageSubType,
  type WebNotificationViewModel,
} from '@cca-infra/notification-service/v1';

@Component({
  selector: 'cca-notification',
  imports: [IconComponent, CdkDatePipe],
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [provideTranslocoScope('notifications')],
})
export class NotificationComponent {
  @HostBinding('class')
  classes = 'block border-neutral-default rounded-xl surface-neutral-default';

  readonly notification = input.required<WebNotificationViewModel>();

  readonly closeNotification = output<WebNotificationViewModel>();

  readonly openNotification = output<WebNotificationViewModel>();

  onClose(event: MouseEvent) {
    event.stopPropagation();
    this.closeNotification.emit(this.notification());
  }

  onClick() {
    this.openNotification.emit(this.notification());
  }

  getNotificationIconType() {
    switch (this.notification().webPushMessageSubType) {
      case WebPushMessageSubType.SystemAutomationFailed:
        return 'warning';
      case WebPushMessageSubType.TaskCreationRequest:
        return 'no-more-task';
      case WebPushMessageSubType.TenderRelated:
        return 'messages-dollar';
      case WebPushMessageSubType.DownloadReady:
        return 'export';
      default:
        return 'invoice';
    }
  }
}
